import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setDisplayPathologiesCount } from './../store/actions/pathologiesCount'

function Pathologies() {
  const dispatch = useDispatch();
  const [selectedPathologyIndex, setSelectedPathologyIndex] = useState(0);
  const [showMeasurements, setShowMeasurements] = useState(false);

  const freehandDrawings = useSelector(
    (state) => state.pathologies.freehandDrawings
  );

  const handleDropdownChange = (event) => {
    setSelectedPathologyIndex(parseInt(event.target.value));
  };

  const toggleMeasurements = () => {
    setShowMeasurements(prevState => !prevState);
  };

  const handlePreviousClick = () => {
    if (selectedPathologyIndex > 0) {
      setSelectedPathologyIndex(selectedPathologyIndex - 2);
    }
  };

  const handleNextClick = () => {
    if (selectedPathologyIndex + 2 < freehandDrawings?.length) {
      setSelectedPathologyIndex(selectedPathologyIndex + 2);
    }
  };

  const displayedPathologies = freehandDrawings?.slice(selectedPathologyIndex, selectedPathologyIndex + 2);

  useEffect(() => {
    const hasMeasurements = displayedPathologies?.some(pathology => pathology?.other?.length > 0);
    if (hasMeasurements) {
      setShowMeasurements(true);
    }
    // console.log('hasMeasurements', hasMeasurements)
    // console.log('showMeasurements', showMeasurements)
  }, []);

  useEffect(() => {
    dispatch(setDisplayPathologiesCount(freehandDrawings?.length || 0));
  }, [freehandDrawings, dispatch]);

  return (
    <div style={{
      background: "#081624", 
      width: "100%", 
      height: "100%", 
      padding: "20px", 
      borderRadius: 7, 
      position: 'relative',
      marginBottom: '20px'
    }}>
      {displayedPathologies?.some(pathology => pathology?.other?.length > 0) &&       
        <div style={{ marginBottom: "20px" }}>
          <button onClick={toggleMeasurements} style={{ float: "left", backgroundColor: 'transparent', border: 'none', color: '#0473b4' }}>
            Measurements {showMeasurements ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
          </button>
        </div>
      }
      <button 
        onClick={handlePreviousClick} 
        style={{ 
          position: 'absolute', 
          left: 0, 
          top: '50%', 
          transform: 'translateY(-50%)', 
          backgroundColor: 'transparent', 
          border: 'none', 
          color: 'darkgray'
        }}
        onMouseEnter={(e) => (e.target.style.color = '#f0b93b', e.target.size = 'lg')}
        onMouseLeave={(e) => (e.target.style.color = 'darkgray', e.target.size = 'sm')}
      >
        <FontAwesomeIcon icon={faArrowLeft} size="sm"/>
      </button>
      <button 
        onClick={handleNextClick} 
        style={{ 
          position: 'absolute', 
          right: 0, 
          top: '50%', 
          transform: 'translateY(-50%)', 
          backgroundColor: 'transparent', 
          border: 'none', 
          color: 'darkgray'
        }}
        onMouseEnter={(e) => (e.target.style.color = '#f0b93b', e.target.size = 'lg')}
        onMouseLeave={(e) => (e.target.style.color = 'darkgray', e.target.size = 'sm')}
      >
        <FontAwesomeIcon icon={faArrowRight} size="sm"/>
      </button>

      {displayedPathologies?.map((item, index) => (
        <div key={index} style={{ width: "96%", margin: 'auto', color: '#0473b4' }}>
          <div style={{ display: "flex", flexDirection: "column", width: '100%', marginBottom: index % 2 === 0 ? '20px' : '0'}}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className='no-bottom-margin' style={{ color: "#fff", width: '100%', fontSize: '20px', marginBottom: '0 !important' }}>
                {`${selectedPathologyIndex + index + 1} ${item?.text}`} 
              </p>
              <p className='no-bottom-margin' style={{ color: "#fff", fontSize: '14px', marginTop: '6px' }}>
                  {item?.mapped_stats?.xy?.mean !== undefined && <>Mean:{item?.mapped_stats?.xy?.mean?.toFixed(2)}{item?.mapped_stats?.xy?.modalityUnit}</>}
              </p>
            </div>
            <p style={{ fontSize: '14px', color: 'darkgray' }}>{item?.window} Window</p>
            {showMeasurements && item?.other?.length > 0 &&
            (<div style={{ height: "2px", backgroundColor: "#fff", width: '100%' }}></div>)}
            <div style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
              {item?.other?.map((measurement) => (
                <div key={measurement?.view?.trim()} style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                  {showMeasurements && (
                    <>
                      <h8 style={{ color: "#fff", fontSize: '12px', color: '#0473b4' }}>{measurement?.view?.trim()}</h8>
                      <p style={{ color: "#fff", fontSize: '10px' }}>{measurement?.stats?.length?.toFixed(2)}mm</p>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Pathologies;
